@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .nav {
    @apply text-sm my-2 sm:text-xl cursor-pointer font-semibold text-gray-500 hover:scale-75 duration-150 hover:text-gray-300;
  }
   .input {
    @apply border outline-green-200 p-2 rounded-lg text-gray-500 w-full;
  }  
  .btn {
    @apply p-2 outline-none w-full mb-2 text-white capitalize font-bold text-xl bg-green-400 rounded-md cursor-pointer flex items-center transition-all duration-75 active:scale-90 ease-in-out justify-center sm:p-3;
  }
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  font-size: 22px !important;
  color: white;
}

.swiper-button-prev,
.swiper-button-next {
  padding: 10px 20px;
  background: blue;
  font-weight: 900!important;
}

.min-height{
  min-height: 90vh;
}
.swiper-slide{

}
.swiper-button-next{
  margin-left: 50px;
}
.swiper-button-prev{
  margin-right: 50px;
}
